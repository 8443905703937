<script setup lang="ts">
const { openWidget } = useCookieConsent()

const locale = computed(() => useI18n().locale.value === 'en' ? 'en/' : '')
</script>

<template>
  <footer>
    <div class="bg-[#061011] text-white text-base mt-20">
      <div class="mx-auto max-w-7xl px-8 py-10">
        <img src="/branding/Logo-Hanselmann-ohne Slogan_weiss.png" class="h-9">

        <div class="space-y-4 md:space-y-0 md:grid md:grid-cols-4 md:gap-8 mt-8">
          <div class="flex flex-col gap-4">
            <p class="text-xl font-semibold">
              {{ $t('customerModule.footer.section.locations') }}
            </p>

            <address class="not-italic">
              Hanselmann GmbH<br>
              Rotebachring 39<br>
              74564 Crailsheim
            </address>
            <address class="not-italic">
              <span class="font-semibold">
                {{ $t('customerModule.footer.section.locations.warehouse') }}
              </span><br>
              Roßfelderstraße 42<br>
              74564 Crailsheim
            </address>
            <address class="not-italic">
              <span class="font-semibold">
                {{ $t('customerModule.footer.section.locations.logisticsCenter') }}
              </span><br>
              Steinäckerstraße 16<br>
              74585 Rot am See
            </address>
          </div>

          <!--
            NOTE: German paths to english pages work because Hanselmann sets their own redirect to the appropriate english pages.
            We rely on them to make sure the links redirect to the correct pages.
            See: https://github.com/sidestream-tech/hanselmann-os/pull/4349#issuecomment-2698326394
          -->
          <div class="flex flex-col gap-4">
            <p class="text-xl font-semibold">
              {{ $t('customerModule.footer.section.company') }}
            </p>
            <NuxtLink :to="`https://hanselmann.de/${locale}ueber-uns/`" external>
              {{ $t('customerModule.footer.section.company.about') }}
            </NuxtLink>
            <NuxtLink :to="`https://hanselmann.de/${locale}ueber-uns/karriere/`" external>
              {{ $t('customerModule.footer.section.company.career') }}
            </NuxtLink>
            <NuxtLink :to="`https://hanselmann.de/${locale}kontakt/`" external>
              {{ $t('customerModule.footer.section.company.contact') }}
            </NuxtLink>
            <NuxtLink :to="`https://hanselmann.de/${locale}agb/`" external>
              {{ $t('customerModule.footer.section.company.gtc') }}
            </NuxtLink>
            <NuxtLink :to="`https://hanselmann.de/${locale}impressum/`" external>
              {{ $t('customerModule.footer.section.company.imprint') }}
            </NuxtLink>
            <NuxtLink :to="`https://hanselmann.de/${locale}datenschutz/`" external>
              {{ $t('customerModule.footer.section.company.dataPrivacy') }}
            </NuxtLink>
          </div>

          <div class="flex flex-col gap-4">
            <p class="text-xl font-semibold">
              {{ $t('customerModule.footer.section.knowledge') }}
            </p>
            <NuxtLink :to="`https://hanselmann.de/${locale}ueber-uns/news/`" external>
              {{ $t('customerModule.footer.section.knowledge.news') }}
            </NuxtLink>
            <NuxtLink :to="`https://hanselmann.de/${locale}ueber-uns/referenzen/`" external>
              {{ $t('customerModule.footer.section.knowledge.references') }}
            </NuxtLink>
          </div>

          <div class="flex flex-col gap-4">
            <p class="text-xl font-semibold">
              {{ $t('customerModule.footer.section.donation') }}
            </p>
            <img src="/branding/Hanselmann_Distribute_Better.svg" class="w-[180px]">
            <p>
              {{ $t('customerModule.footer.section.donation.text') }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="PagePadding mx-auto py-2 text-center bg-[#EFEFEF]">
      <p class="text-lg">
        {{ $t('customerModule.footer.copyright') }}
      </p>
      <p class="text-lg text-hos-blue cursor-pointer" @click="openWidget">
        {{ $t('customerModule.footer.cookies') }}
      </p>
    </div>
  </footer>
</template>
